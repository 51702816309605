import React from "react";
import { FormattedMessage } from 'react-intl'
import {IntlProvider} from 'react-intl';
const NotFoundPage = () => (

  <div>

     <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>

);

export default NotFoundPage;
